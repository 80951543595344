const VALUES = [
    {
        identifier: "circle-item-candle",
        title: "Knowledge sharing",
        text: "An everlasting candle burns at the entrance of Random_Kingdom. If you brought your own, you can light it up with this ancient flame. Your light will be seen by others, and your path will be enlighten.",
    },
    {
        identifier: "circle-item-nut",
        title: "Co-creation",
        text: "You’ll found your acorn in the wet ground of a forest. By picking it up, you knew it would have been the seed of your creativity. In fact, every visitor who wants to open R_K’s gate needs to donate one at the entrance. To each visitor, corresponds a tree that will grow among the others - creating a Forest.",
    },
    {
        identifier: "circle-item-diamont",
        title: "Inclusiveness",
        text: "It’s the first pass you need to extract from your bag. You can find it easily: it is a big heavy transparent gem. When the light touches it a multitude of colors and shapes appears.",
    },
    {
        identifier: "circle-item-ripple",
        title: "Community",
        text: "No matter the diameter. No matter the color. Your unique metal ring will be forged into a never-ending chain. Stronger every day, each ring connects with another, the chain will never break.",
    },
    {
        identifier: "circle-item-drop",
        title: "Kindness",
        text: "Your last key is not something you found on your way. It’s a feeling that is growing bigger and bigger inside your heart. Keep it there, smile, and let a tear of happiness fall from your eyes through your cheeks, before it finally hit the ground. Your precious gift will open the gate.",
    },
    {
        identifier: "circle-item-butterfly",
        title: "Mutual aid",
        text: "Pretty rare to find, So essential to have. Its healing properties make it miraculous. It will help you, it will help your friends. Sharing is Caring.",
    },
    {
        identifier: "circle-item-sword",
        title: "Exploration",
        text: "The Kingdom is vast and full of surprises. Don’t get lost. This little compass will help you discover the most hidden and remote corners of the Kingdom.",
    },
];


class SVGInteractionManager {
    constructor() {
        this.hoveredItems = new Set();
        this.textOverlay = this.createTextOverlay();
        this.isVisible = false;
        this.touchStartTime = 0;
        this.touchStartPosition = { x: 0, y: 0 };

        console.log(" CONSTRUCTTEDDDDD!")
    }

    createTextOverlay() {
        const overlay = document.createElement("div");

        Object.assign(overlay.style, {
            position: "absolute",
            textAlign: "center",
            pointerEvents: "auto",
            transition: "opacity 0.3s",
            opacity: "0",
            transform: "translate(-50%, -50%)",
            width: "200px",
            fontSize: "14px",
            textShadow: "1px 1px 5px black",
            color: "white",
            fontFamily: "Ogg Text",
            lineHeight: "1.5",
            padding: "10px"
        });

        document.body.appendChild(overlay);
        return overlay;
    }

    initializePortal(svgDoc) {
        const portalElement = svgDoc.querySelector('.circle-item-portal');

        if (!portalElement) return;

        const navigateToAbout = () => window.location.href = '/about';

        portalElement.addEventListener('click', (e) => {
            e.stopPropagation();
            navigateToAbout();
        });

        portalElement.addEventListener('keydown', (e) => {
            if (e.key === 'Enter' || e.key === ' ') navigateToAbout();
        });

        portalElement.style.pointerEvents = 'auto';
        return portalElement;
    }

    showText(text, svgObject, portalElement) {
        const svgRect = svgObject.getBoundingClientRect();
        const svgCenterX = svgRect.left + svgRect.width / 2;
        const svgCenterY = svgRect.top + svgRect.height / 2;

        if (this.textOverlay.style.opacity === "1") {
            this.textOverlay.style.transition = "none";
        }

        Object.assign(this.textOverlay.style, {
            left: `${svgCenterX}px`,
            top: `${svgCenterY}px`,
            opacity: "1"
        });
        
        this.textOverlay.textContent = text;

        if (this.textOverlay.style.transition === "none") {
            setTimeout(() => {
                this.textOverlay.style.transition = "opacity 0.3s";
            }, 0);
        }

        if (portalElement) {
            portalElement.style.visibility = "hidden";
        }

        this.textOverlay.onclick = () => {
            if (this.textOverlay.style.opacity === "1") {
                window.location.href = '/about';
            }
        };
    }

    hideText(portalElement) {
        this.textOverlay.style.opacity = "0";
        this.textOverlay.onclick = null;
        if (portalElement) {
            portalElement.style.visibility = "visible";
        }
    }

    initializeSVG(svgDoc) {
        if (!svgDoc) {
            console.error('SVG document not loaded properly');
            return;
        }

        const svgObject = document.querySelector("object");
        const portalElement = this.initializePortal(svgDoc);

        VALUES.forEach(({ identifier, text }) => {
            const elements = svgDoc.getElementsByClassName(identifier);
            if (!elements?.length) {
                console.warn(`No elements found for identifier: ${identifier}`);
                return;
            }

            Array.from(elements).forEach(element => {
                this.attachEventListeners(element, identifier, text, svgObject, portalElement);
            });
        });
    }

    attachEventListeners(element, identifier, text, svgObject, portalElement) {
        const showTextBound = () => {
            this.showText(text, svgObject, portalElement);
            this.hoveredItems.add(identifier);

            if (this.hoveredItems.size === VALUES.length) {
                console.log("Congratulations! You have discovered all items!");
            }
        };

        const hideTextBound = () => this.hideText(portalElement);

        // Desktop events
        element.addEventListener("mouseenter", showTextBound);
        element.addEventListener("mouseleave", hideTextBound);

        // Improved mobile events
        element.addEventListener("touchstart", (e) => {
            // Store touch start time and position for later comparison
            this.touchStartTime = Date.now();
            this.touchStartPosition = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            };
            
            // Don't prevent default immediately to allow scrolling
            e.stopPropagation();
        });

        element.addEventListener("touchend", (e) => {
            const touchEndTime = Date.now();
            const touchDuration = touchEndTime - this.touchStartTime;
            
            // Calculate touch movement
            const touch = e.changedTouches[0];
            const moveDistance = Math.hypot(
                touch.clientX - this.touchStartPosition.x,
                touch.clientY - this.touchStartPosition.y
            );

            // Only trigger if it was a quick tap (< 200ms) and didn't move much (< 10px)
            if (touchDuration < 200 && moveDistance < 10) {
                e.preventDefault();
                this.isVisible = !this.isVisible;
                this.isVisible ? showTextBound() : hideTextBound();
            }
        });

        // Add touch move handler to prevent unwanted interactions while scrolling
        element.addEventListener("touchmove", (e) => {
            const touch = e.touches[0];
            const moveDistance = Math.hypot(
                touch.clientX - this.touchStartPosition.x,
                touch.clientY - this.touchStartPosition.y
            );
            
            // If moved more than 10px, assume it's a scroll
            if (moveDistance > 10) {
                this.isScrolling = true;
            }
        });
    }
}

// Initialize
document.addEventListener("DOMContentLoaded", () => {
    const manager = new SVGInteractionManager();
    const svgObject = document.querySelector("object");

    if (!svgObject) {
        console.error('SVG object not found in the DOM');
        return;
    }

    if (svgObject.contentDocument?.readyState === 'complete') {
        manager.initializeSVG(svgObject.contentDocument);
    }

    svgObject.addEventListener("load", () => {
        manager.initializeSVG(svgObject.contentDocument);
    });

    svgObject.addEventListener("error", (error) => {
        console.error('Error loading SVG:', error);
    });
});

console.log("fooo!")